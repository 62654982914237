<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-data-table
      :headers="headers"
      :items="stockAdjustments"
      :loading="isLoadingStockAdjustments"
      :server-items-length="stockAdjustmentsMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
      @click:row="editItem"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('stockAdjustment-update')"
          class="mr-2"
          color="#C6C6C6"
          @click.stop="editItem(item)"
        >
          $vuetify.icons.iconEditItem
        </v-icon>
        <v-icon
          class="mr-2"
          v-if="$admin.can('stockAdjustment-delete')"
          @click.stop="deleteItem(item)"
        >
          $vuetify.icons.iconDeleteItem
        </v-icon>
      </template>
      <template v-slot:top>
        <v-row class="my-2">
          <v-col
            cols="12"
            sm="2"
            md="2"
          >
            <v-autocomplete
              outlined
              clearable
              dense
              hide-details
              v-model="filters.hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_hub"
              :loading="is_loading_hubs"
              no-filter
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            sm="3"
            class="px-0"
          >
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  v-model="filters.created_date_range"
                  :label="$t('created_date_range')"
                  append-icon="mdi-calendar"
                  readonly
                  chips
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.created_date_range"
                range
                no-title
                color="primary"
                class="my-0 py-4"
                header-color="primary"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
    </v-data-table>
    <delete-adjustment-stock-modal
      v-if="$admin.can('stockAdjustment-delete') && isDeleteModalOpen"
      :isDeleteModalOpen="isDeleteModalOpen"
      :stockAdjustment="currentAdjustment"
      :toggleDeleteModal="() => toggleDeleteModal()"
    ></delete-adjustment-stock-modal>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  components: {
    DeleteAdjustmentStockModal: () =>
      import("../modals/DeleteAdjustmentStockModal"),
  },

  computed: {
    ...mapGetters({
      isLoadingStockAdjustments: "stockAdjustments/isLoadingStockAdjustments",
      stockAdjustments: "stockAdjustments/list",
      stockAdjustmentsMeta: "stockAdjustments/meta",
      hubs: "hubs/activeHubs",
    }),

    isVisibleDialog: {
      get() {
        return this.$store.state.stockAdjustments.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("stockAdjustments/IS_VISIBLE_DIALOG", value);
      },
    },
    filters: {
      get() {
        return this.$store.state.stockAdjustments.filters;
      },
      set(value) {
        this.$store.commit("stockAdjustments/SET_FILTERS", value);
      },
    },
  },
  props: {
    isActive: {
      types: Boolean,
    },
  },

  watch: {
    options: {
      handler: debounce(async function (params) {
        if (this.isActive) {
          await this.$store
            .dispatch("stockAdjustments/list", {
              ...params,
              ...this.filters,
            })
            .then(() => {})
            .catch(() => {
              this.$store.dispatch("alerts/error", "stockAdjustment not found");
            });
        }
      }, 1000),
      deep: true,
    },

    filters: {
      handler: debounce(async function (params) {
        if (this.isActive) {
          await this.$store
            .dispatch("stockAdjustments/list", {
              ...params,
              ...this.options,
            })
            .then(() => {})
            .catch(() => {
              this.$store.dispatch(
                "alerts/error",
                "stockAdjustments not found"
              );
            });
        }
      }, 1000),
      deep: true,
    },

    search_hub: debounce(function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;

      this.$store
        .dispatch("hubs/list", {
          search,
        })
        .then(() => {
          this.is_loading_hubs = false;
        });
    }, 1000),
  },

  data() {
    return {
      search_hub: "",
      is_loading_hubs: false,
      options: {},
      index: 0,
      is_loading: false,
      creation_date: null,
      currentAdjustment: null,
      isDeleteModalOpen: false,

      headers: [
        {
          text: this.$t("creation_date"),
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$t("stockAdjustments.adjusted_by"),
          align: "start",
          sortable: false,
          value: "created_by.name",
        },
        {
          text: this.$t("hub"),
          align: "start",
          sortable: false,
          value: "hub.name",
        },
        {
          text: this.$t("stockAdjustments.number_of_items"),
          align: "center",
          sortable: true,
          value: "products_count",
        },

        {
          text: this.$t("actions"),
          value: "actions",
          align: "end",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    editItem(item) {
      this.$router.push({
        name: "stockAdjustments.edit",
        params: { id: item.id },
      });
    },
    toggleDeleteModal() {
      this.isDeleteModalOpen = !this.isDeleteModalOpen;
    },

    deleteItem(item) {
      this.isDeleteModalOpen = true;
      this.currentAdjustment = item;
    },
  },
};
</script>
